// @import "~@fullcalendar/core/main.css";
// @import "~@fullcalendar/daygrid/main.css";
// @import "~@fullcalendar/timegrid/main.css";

.demo-app-calendar {
  margin: 0 auto;
  min-height: 1000px;

  .fc-day-header {
    background-color: #fff; //body background
  }

  .large-row {
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  @media (max-width: 623px) {
    .fc-title {
      font-size: 8px;
    }
  }

  .fc-scroller {
    overflow-y: hidden !important;
  }

  .fc-button-primary {
    background-color: #677080 !important;
    border-color: #fff;
  }

  .modal-footer i {
    font-size: 2.125rem;
  }

  table thead tr th,
  table thead tr td,
  table tbody tr th,
  table tbody tr td,
  table tfoot tr th,
  table tfoot tr td {
    vertical-align: top !important;
  }
}
