/*============ Advance Ui Elements ===========*/
// Tabs Scss
.custom-tabs {
  border: none;
  .nav-item {
    .nav-link {
      border: none;
      border-radius: 50px;
      &:hover, &:focus, &.active {
        border: none;
        color: $gray-900;
        background: rgba(0,0,0,0.2);
      }
    }
  }
}
// Sweet Alerts
.sweet-alert  {
  .btn {
    padding: 0.3rem 2rem;
    font-size: 1rem;
  }
}
.custom-alert{
	h2{
		color:$white !important;
	}
}
// date-picker icon
.date-picker-icon{
   height:auto !important;
}
// auto select
.auto-select-wrap{
   .react-single-wrap{
		padding:2px 0;
		height: auto;
      > div{
         border:0 !important;
         box-shadow:none !important;
         >div:first-child{
            padding: 0;
         }
         >div:nth-child(2) span{
            display: none;
         }
         &:focus,&:hover{
            box-shadow: none !important;
         }
      }
   }
}
//Horizontal stepper
@media (max-width: 767px){
	.select-dropdown{
		margin-bottom: 30px;
	}
}
@media (max-width: 575px){
	.hoz-linear-stepper{
		margin-left: -14px;
		>div:first-child{
			padding-left:0;
			padding-right: 0;
		}
		>div:nth-child(2){
			padding-left:10px;
		}
	}
	.rbc-calendar .rbc-toolbar button{
		padding: .375rem 0.5rem;
	}
}