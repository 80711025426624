//Extensions

/*================ Image Cropper =============*/
.image-cropper-wrap {
  .rct-block-content {
    > div {
      overflow: hidden;
    }
    .cropper-container {
      &.cropper-bg {
        width: 100% !important;
      }
    }
    @media (max-width: 500px) {
      &.crop-wrapper {
        > div > div:not(.d-flex) {
          height: 250 !important;
        }
      }
      .form-group {
        display: block !important;
        text-align: center;
        .w-30,
        .w-40 {
          width: 100% !important;
        }
        .form-control-file {
          width: 46% !important;
          margin: 0 auto;
        }
      }
    }
  }
}
